body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    /* background: rgb(249,247,247);
    background: linear-gradient(90deg, rgba(135,202,215,1) 0%, #34c759 100%);   
    font-family: 'Arial', sans-serif; */
    background: linear-gradient(to bottom, #e0f7fa, #ffffff);
    font-family: 'Arial', sans-serif;
}

.chatbox {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 80vh;
    background-color: black;
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.chat-messages {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
}

.chat-message {
    margin-bottom: 15px;
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 20px;
    display: inline-block;
    line-height: 1.5;
}

.chat-message.user {
    background-color: #007aff;
    color: white;
    align-self: flex-end;
}

.chat-message.assistant {
    background-color: #34c759;
    color: white;
    align-self: flex-start;
}

.chat-input {
    display: flex;
    padding: 10px;
    background-color: #2c2c2c;
    border-top: 1px solid #444;
}

.chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
    outline: none;
    background-color: #1c1c1c;
    color: white;
}

.chat-input button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007aff;
    color: white;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #005bb5;
}

.chat-message.loading {
    background-color: gray;
    color: white;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
}

.chat-message.loading .dots {
    display: flex;
    margin-left: 10px;
}

.chat-message.loading .dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: white;
    animation: bounce 0.6s infinite alternate;
}

.chat-message.loading .dot:nth-child(2) {
    animation-delay: 0.2s;
}

.chat-message.loading .dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    to {
        opacity: 0.3;
        transform: translateY(-8px);
    }
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #007aff;;
}

.camera-icon {
    color: #007aff;
    font-size: 2em;
    cursor: pointer;
    margin-left: 0.4em;
}

.camera-icon:hover {
    color: #005bb5;
}

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    width: 300px;
}

.popup .popup-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.popup-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.popup-option p {
    margin-top: 10px;
    font-size: 14px;
    color: #007aff;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff3b30;
    cursor: pointer;
    font-size: 1.5em;
}

.close-icon:hover {
    color: #d32f2f;
}

.image-icon {
    color: #007aff;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
}

h1 .blue-text {
    color: #007aff;
    font-family: 'Courier New', Courier, monospace;
}

.camera-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.camera-popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    width: 400px;
}

.camera-popup video {
    width: 100%;
}

.camera-popup button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007aff;
    color: white;
    cursor: pointer;
}

.camera-popup button:hover {
    background-color: #005bb5;
}

a {
    color: #007aff;
    text-decoration: none;
}